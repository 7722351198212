<template>
  <notifications
    class="mt-5"
    :duration="15000"
    position="top right"
  >
    <template #body="{ item }">
      <v-alert
        border="left"
        dismissible
        elevation="24"
        prominent
        :type="interpretType(item.type)"
      >
        {{ item.text }}
      </v-alert>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'push-alert',
  methods: {
    interpretType (type) {
      return type === 'warn' ? 'warning' : type
    }
  }
}
</script>
