<template>
  <v-card
    class="mx-auto my-12 pa-4 rounded-lg flex flex-row"
    elevation="2"
  >
    <p class="text-body-1 font-weight-bold">Фильтр</p>
    <v-row>
<!--      <v-col cols="12" sm="3">-->
<!--        <v-select-->
<!--          v-model="serviceId"-->
<!--          :items="services"-->
<!--          item-text="text"-->
<!--          item-value="value"-->
<!--          label="Сервис"-->
<!--          dense-->
<!--          outlined-->
<!--          @change="$emit('onServiceChange', $event)"-->
<!--        />-->
<!--      </v-col>-->
      <v-col cols="12" sm="3">
        <v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFrom"
              label="От:"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            @input="handleInputDateFrom"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              label="До:"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateTo"
            @input="handleInputDateTo"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="state"
          :items="states"
          item-text="text"
          item-value="value"
          label="Статус"
          dense
          outlined
          @change="$emit('onStateChange', $event)"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="username"
          placeholder="Телефон"
          dense
          outlined
          @input="$emit('onUsernameChange', $event)"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-10 flex-wrap">
      <v-btn
        color="primary"
        @click="resetFilters"
      >
        Сбросить фильтры
      </v-btn>
      <v-btn
        class="ml-3 mt-3 mt-sm-0"
        color="primary"
        @click="$emit('onExportPaymentsCLick', { state, serviceId })"
      >
        Выгрузить отчет
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Payments-filters',
  data () {
    return {
      menuFrom: false,
      menuTo: false,
      phone: '',
      serviceId: '',
      state: '',
      dateFrom: '',
      dateTo: '',
      username: ''
    }
  },
  props: {
    services: {
      type: Array,
      required: true
    },
    states: {
      type: Array,
      required: true
    }
  },
  methods: {
    resetFilters () {
      this.state = ''
      this.serviceId = ''
      this.dateFrom = ''
      this.dateTo = ''
      this.username = ''
      this.$emit('resetFilters')
    },
    handleInputDateFrom () {
      this.menuFrom = false
      this.$emit('inputDateFrom', this.dateFrom)
    },
    handleInputDateTo () {
      this.menuTo = false
      this.$emit('inputDateTo', this.dateTo)
    }
  }
}
</script>
