import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentsView from '../views/PaymentsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/payments'
  },
  {
    path: '/payments',
    name: 'payments',
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('accessToken')) {
        next('login')
      } else {
        next()
      }
    },
    component: PaymentsView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
  },
  {
    path: '/create',
    name: 'create',
    component: () => import(/* webpackChunkName: "create" */ '../views/RegisterPage.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
