import axios from 'axios'

const authRequestInterceptor = (config) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

const HTTP = axios.create({
  baseURL: 'https://api.megafamily.kz',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
})

HTTP.interceptors.request.use(authRequestInterceptor)

export { HTTP }
