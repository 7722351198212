import { HTTP } from '../http'

const getPayments = (params) => {
  return HTTP.get('/v1/payments', { params })
}

const exportPayments = (params) => {
  return HTTP.get('/v1/payments/export', { params })
}

export {
  getPayments,
  exportPayments
}
