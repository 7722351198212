import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: JSON.parse(localStorage.getItem('darkMode')),
    themes: {
      dark: {
        primary: '#455A64',
        secondary: '#37474F'
      },
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  }
})
