<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1"
    disable-pagination
    fixed-header
    disable-sort
    disable-filtering
    :hide-default-footer="true"
  ></v-data-table>
</template>

<script>
export default {
  name: 'payments-table',
  props: {
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  }
}
</script>
