<template>
  <div>
    <payments-filters
      :services="services"
      :states="states"
      @onStateChange="handleStateChange"
      @onServiceChange="handleServiceChange"
      @onUsernameChange="handleUsernameChange"
      @onExportPaymentsCLick="exportPaymentsRequest"
      @resetFilters="handleResetFilters"
      @inputDateFrom="handleDateFrom"
      @inputDateTo="handleDateTo"
    />
    <payments-table
      :headers="paymentHeaders"
      :items="paymentItems"
    />
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="9">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
        <v-col cols="4" sm="3">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import PaymentsTable from '../components/payments-table'
import PaymentsFilters from '@/components/Payments-filters'
import { getServices } from '@/services/api/services'
import { getPayments, exportPayments } from '@/services/api/payments'
import { format } from 'date-fns'
import { MEGA_SLUG_DICT } from '@/utils/constants'

export default {
  name: 'HomeComponent',
  components: {
    PaymentsTable,
    PaymentsFilters
  },
  data () {
    return {
      paymentItems: [],
      page: 1,
      totalPages: 0,
      pageSize: 20,
      pageSizes: [10, 20, 50],
      interval: null,
      services: [],
      state: null,
      serviceId: null,
      createdAtFrom: '',
      createdAtTo: '',
      username: ''
    }
  },
  computed: {
    paymentHeaders () {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Телефон', value: 'phone' },
        { text: 'Сумма', value: 'amount' },
        { text: 'Сумма бонусов', value: 'bonusAmount' },
        { text: 'Дата', value: 'createdAt' },
        { text: 'Статус', value: 'status' }
      ]
    },
    states () {
      return [
        { text: 'Все', value: '' },
        { text: 'Отменен', value: 'cancelled' },
        { text: 'Создано', value: 'created' },
        { text: 'Оплачено', value: 'paid' }
      ]
    }
  },
  async mounted () {
    await getServices().then(response => {
      const { data } = response
      this.services = data.items.map(item => ({
        text: `${item.title} - ${MEGA_SLUG_DICT[item.center_slug]}`,
        value: item.id
      }))
    })

    this.retrievePayments()
    this.interval = setInterval(() => {
      this.retrievePayments()
    }, 20000)
  },
  methods: {
    getRequestParams ({ page, pageSize, state, serviceId, username, createdAtFrom, createdAtTo }) {
      const params = {}
      if (page) {
        params.page = page
      }
      if (pageSize) {
        params.size = pageSize
      }
      if (state) {
        params.state = state
      }
      if (serviceId) {
        params.service_id = serviceId
      }
      if (username) {
        params.username = username
      }
      if (createdAtFrom) {
        params.created_at__gt = createdAtFrom
      }
      if (createdAtTo) {
        params.created_at__lt = createdAtTo
      }
      return params
    },
    retrievePayments () {
      const params = this.getRequestParams({
        page: this.page,
        pageSize: this.pageSize,
        state: this.state,
        serviceId: this.serviceId,
        username: this.username,
        createdAtFrom: this.createdAtFrom,
        createdAtTo: this.createdAtTo
      })
      getPayments(params)
        .then(response => {
          const { data } = response
          this.paymentItems = this.createItems(data.items)
          this.pageSize = data.size
          this.page = data.page
          this.totalPages = Math.floor(data.total / data.size)
          if (this.getTotal() === null) {
            sessionStorage.setItem('paymentsTotal', String(data.total))
          }
          if (this.getTotal() < data.total) {
            console.log(this.getTotal())
            this.$notify({
              text: 'Была произведена новая оплата!',
              type: 'success'
            })
            sessionStorage.setItem('paymentsTotal', String(data.total))
          }
        })
    },
    createItems (items) {
      return items.map(item => ({
        id: item.id,
        phone: item.profile?.username,
        amount: item.amount,
        bonusAmount: item.bonus_amount,
        createdAt: format(new Date(item.created_at), 'dd.MM.yyyy hh:mm'),
        status: item.state
      }))
    },
    handlePageChange (value) {
      this.page = value
      this.retrievePayments()
    },
    handlePageSizeChange (size) {
      this.pageSize = size
      this.page = 1
      this.retrievePayments()
    },
    getTotal () {
      return Number(sessionStorage.getItem('paymentsTotal'))
    },
    handleStateChange (state) {
      this.state = state
      this.retrievePayments()
    },
    handleUsernameChange (username) {
      this.username = username
      this.retrievePayments()
    },
    handleServiceChange (serviceId) {
      this.serviceId = serviceId
      this.retrievePayments()
    },
    handleResetFilters () {
      this.state = null
      this.serviceId = null
      this.createdAtTo = ''
      this.createdAtFrom = ''
      this.username = ''
      this.retrievePayments()
    },
    handleDateFrom (dateFrom) {
      this.createdAtFrom = `${dateFrom}T00:00:00`
      this.retrievePayments()
    },
    handleDateTo (dateTo) {
      this.createdAtTo = `${dateTo}T00:00:00`
      this.retrievePayments()
    },
    exportPaymentsRequest ({ state, serviceId, username, createdAtFrom, createdAtTo }) {
      const params = this.getRequestParams({
        state: this.state,
        serviceId: this.serviceId,
        username: this.username,
        createdAtFrom: this.createdAtFrom,
        createdAtTo: this.createdAtTo
      })

      exportPayments(params)
        .then(response => {
          const link = document.createElement('a')
          link.href = response.data.file
          link.setAttribute('download', 'export.xlsx')
          document.body.appendChild(link)
          link.click()
        }).catch(err => {
          this.$notify({
            text: err.response.data.detail,
            type: 'error'
          })
        })
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>
