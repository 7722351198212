<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <button style="border: none" @click="oneClick">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="/mega-logo.svg"
            transition="scale-transition"
            width="40"
          />
        </button>
      </div>

      <v-btn
        v-if="isAuth"
        class="ml-10"
        text
        color="white"
        to="/payments"
      >
        Payments
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        icon
        color="white"
        @click="changeTheme"
      >
        <v-icon>{{ $vuetify.theme.dark ? 'mdi-lightbulb-on' : 'mdi-lightbulb' }}</v-icon>
      </v-btn>
      <v-btn v-if="isAuth" text @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <push-alert />
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import PushAlert from '@/components/push-alert'
export default {
  name: 'App',
  components: { PushAlert },
  data: function () {
    return {
      result: [],
      delay: 700,
      clicks: 0,
      timer: null
    }
  },
  computed: {
    isAuth () {
      return localStorage.getItem('accessToken')
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('accessToken')
      window.location.reload()
    },
    oneClick (event) {
      this.clicks++
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.result.push(event.type)
          this.clicks = 0
        }, this.delay)
      } else {
        clearTimeout(this.timer)
        this.result.push('dblclick')
        this.$router.push({ name: 'create' })
        this.clicks = 0
      }
    },
    changeTheme () {
      localStorage.setItem('darkMode', JSON.stringify(!this.$vuetify.theme.dark))
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
}
</script>
